import React from "react";
import { NavLink } from "react-router-dom";
import smallLogo from "assets/imgs/smallLogo.svg";
import logo from "assets/imgs/enoviimaxWhite.svg";

const SidebarHeader = (props) => {
  let { collapsed, sidebarState, scrollFromTop } = props;
  const isSmallLogo = collapsed && sidebarState;
  const homePageLink = "/";

  return (
    <div
      className={`navbar-header d-flex justify-content-${
        isSmallLogo ? "start" : "center"
      } align-items-center ${scrollFromTop > 50 && "navbar-header-shadow"}`}
    >
      <ul
        className={`nav navbar-nav flex-row ${
          isSmallLogo ? "" : "justify-content-center align-items-center"
        } `}
      >
        <li className="nav-item">
          <NavLink
            to={homePageLink}
            className={`d-flex justify-content-${
              isSmallLogo ? "start" : "center"
            } align-items-center`}
          >
            {isSmallLogo ? (
              <img src={smallLogo} alt="logo" className="w-50" />
            ) : (
              <img src={logo} alt="logo" className="w-75" />
            )}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SidebarHeader;
