import { SC } from "utility/helper";
import { history } from "utility/helper/history";
import { checkOTP } from "utility/helper/checkOTP";

export const loginWithJWT = (payload) => {
  return { type: "LOGIN_WITH_JWT", payload };
};

export const expireUser = (payload) => {
  return { type: "LOGOUT", payload };
};

export const getUser = () => {
  return async (dispatch) => {
    SC.getCall({ url: "/token_data" }).then((response) => {
      const responseCode = response.data.code;
      const OTPUsed = response.data.data?.OTPUsed;
      if (responseCode === 200) {
        dispatch(loginWithJWT(response?.data?.data));
        checkOTP(OTPUsed);
      } else {
        history.push("/login");
      }
    });
  };
};

export const login = (data) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const token = data?.data?.token;
    const OTPUsed = data?.data?.OTPUsed;
    const isUnAuthRedirected =
      auth.login.intendedRoute && auth.login.intendedRoute === "unAuthorized";
    if (token) localStorage.setItem("token", token);
    dispatch(loginWithJWT(data?.data || {}));
    if (!OTPUsed) {
      checkOTP(OTPUsed);
    } else {
      if (isUnAuthRedirected) {
        history.go(-1); // redirect me to last protected page i tried to visit
      } else {
        history.push("/");
      }
    }
  };
};

export const logout = (props) => {
  return async (dispatch) => {
    // handle logout
    localStorage.removeItem("token");
    localStorage.removeItem("cardView");
    dispatch(expireUser(props?.reason));
    if (history.location.pathname !== "/login") history.push("/login");
  };
};
