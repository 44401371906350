const initialState = {
  isOpen: false,
  inventoryForWorkOrder: {},
  openSideBarRouteBase: false,
};
export const drawer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_DRAWER": {
      return {
        ...state,
        isOpen: action.payload,
      };
    }

    case "TOGGLE_SIDE_BAR_ROUTE_BASE": {
      return {
        ...state,
        openSideBarRouteBase: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
