import React from "react";
import * as Icon from "react-icons/fa";
export default [
  {
    id: "manufacturers",
    title: "Manufacturers",
    icon: <Icon.FaUsersCog size={20} />,
    pageTitle: "Manufacturers List",
    navLink: "/manufacturers",
    type: "item",
    permissions: ["super_admin"],
  },
];
