import moment from "moment";
import { env } from "utility/config";
import { PO } from "utility/helper";

export const sortArray = (arr) => {
  const sortedArr = arr.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.created_at) - new Date(a.created_at);
  });

  return sortedArr;
};

export const dataToSubmit = (payload) => {
  let data = {};
  const numberPattern = /\d+/g;
  Object.keys(payload).map((item) => {
    data = {
      ...data,
      [item]:
        item === "phone"
          ? Number(PO?.oneLayer(payload[item])?.match(numberPattern)?.join(""))
          : PO.oneLayer(payload[item]),
    };
  });
  return data;
};

export const splitData = (value, from, to) => {
  return value?.split(from)?.join(to);
};

export const getDate = (d, f) => {
  return moment(new Date(d)).format(f);
};

export const fiveRandomNumbers = (value, from, to) => {
  return Math.random().toString(36).substr(2, 6);
};

// Transformers
export const getJSONArrayUniqueElements = (array, uniqueField) => {
  let JAUE = array?.map((item) => item?.[uniqueField]) || [];
  JAUE = [...new Set(JAUE)];
  return JAUE;
};

export const getOptionObject = (item, value, label) => {
  if (item) {
    return {
      ...item,
      VALUE: item[value],
      LABEL: item[label],
    };
  }
  return item;
};

export const getSelectOptions = (e, custom) => {
  if (custom) {
    return (
      e?.map((item) => {
        return getOptionObject(item, custom?.value, custom?.label);
      }) || []
    );
  } else {
    return Object.values(e || {});
  }
};

export const getFileObj = (item) => {
  let itemName = splitData(item, env.AWS_BUCKET_URL, "");
  itemName = splitData(itemName, "%", " ");
  return {
    id: fiveRandomNumbers(),
    key: itemName,
    name: itemName,
    Location: item,
  };
};

export const getFileExtension = (fileName) => {
  return fileName.split(".")[fileName.split(".").length - 1];
};
export const removeSpaces = (value) => {
  return value.split(" ").join("");
};
//TR= transformers
export const TR = {
  dataToSubmit,
  splitData,
  getFileExtension,
  removeSpaces,
};

export const getFieldValue = (value) => {
  return typeof value === "boolean" ? value : value?.toString();
};
