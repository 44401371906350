import React from "react";
import { GiAutoRepair } from "react-icons/gi";
export default [
  {
    id: "inventoryPlanning",
    title: "Asset Planning",
    icon: <GiAutoRepair size={20} />,
    pageTitle: "Asset Planning",
    navLink: "/inventories/planning",
    type: "item",
    permissions: ["super_admin"],
  },
];
