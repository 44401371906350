import React from "react";
import { FiTool } from "react-icons/fi";

export default [
  {
    id: "equipment",
    title: "Assets",
    icon: <FiTool size={20} />,
    pageTitle: "Assets List",
    navLink: "/equipments",
    type: "item",
    permissions: ["super_admin"],
  },
];
