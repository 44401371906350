import React from "react";
import { FormattedMessage } from "react-intl";
import PoweredByLogo from "assets/imgs/poweredBy.svg";

import help from "./Help.svg";

const NeedHelp = (props) => {
  return (
    <div
      onClick={props.onClick}
      className="pt-1 sidebar__cursorPointer needHelp  mb-1"
    >
      <span className="text-white pt-1 span-help pr-3" onClick={props.onClick}>
        <img
          src={help}
          alt="help"
          onClick={props.onClick}
          className="help-box--icon"
        />
        <span className="ml-2">
          <FormattedMessage
            id={"Need Help?"}
            defaultMessage="Need Help?"
            onClick={props.onClick}
          />
        </span>
      </span>
    </div>
  );
};

const SidebarFooter = ({ isBottomShadow, ...props }) => {
  return (
    <div className={`sidebar__footer  ${isBottomShadow && "sidebar__shadow"}`}>
      <NeedHelp {...props} />
      <a
        href="https://ascend.com.sa/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={PoweredByLogo}
          alt="logo"
          className="authLayout__footer--logo"
        />
      </a>
    </div>
  );
};

export default SidebarFooter;
