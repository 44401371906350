import React from "react";
import { GoOrganization } from "react-icons/go";

export default [
  {
    id: "organizationAdmin",
    title: "Organizations",
    icon: <GoOrganization />,
    pageTitle: "Organization Admins",
    navLink: "/organization-admin",
    type: "item",
    permissions: ["super_admin"],
  },
];
