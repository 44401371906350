import React from "react";
import { IoLayers } from "react-icons/io5";
export default [
  {
    id: "siteDepartments",
    title: "Site Departments",
    icon: <IoLayers size={20} />,
    pageTitle: "Site Departments List",
    navLink: "/siteDepartments",
    type: "item",
    permissions: ["super_admin", "site_admin"],
  },
];
