import { inventoryIcon } from "assets/icons/svgIcons";

export default [
  {
    id: "inventory",
    title: "Inventory",
    icon: inventoryIcon,
    // type: "collapse",
    type: "item",
    navLink: "/inventories",
    pageTitle: "Inventory List",
    permissions: [
      "super_admin",
      "site_admin",
      "organization_admin",
      "staff",
      "engineer",
      "hmc_admin",
      "hmc_supervisor",
      "hmc_technician",
      "qa",
      "helpdesk",
    ],
    children: [
      // {
      //   id: "inventoryCreate",
      //   title: "Add",
      //   icon: <FiCircle size={12} />,
      //   navLink: "/inventories/add",
      //   type: "item",
      //   permissions: ["admin_hmc", "super_admin"],
      // },
      // {
      //   id: "inventoryList",
      //   title: "List",
      //   icon: listIcon,
      //   pageTitle: "Inventories List",
      //   navLink: "/inventories",
      //   type: "item",
      //   permissions: [
      //     "super_admin",
      //     "site_admin",
      //     "staff",
      //     "engineer",
      //     "hmc_admin",
      //     "hmc_supervisor",
      //     "hmc_technician",
      //     "qa",
      //     "helpdesk",
      //   ],
      // },
    ],
  },
  {
    id: "inventory-upcoming",
    title: "Upcoming Out of Warranty Assets",
    icon: inventoryIcon,
    type: "item",
    navLink: "/inventories-upcoming",
    pageTitle: "Upcoming Out of Warranty Assets",
    permissions: ["super_admin"],
    isDisabledFromSideMenu: true,
  },
];
