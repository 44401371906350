import React from "react";
import * as Icon from "react-icons/fa";
export default [
  {
    id: "regions",
    title: "Regions",
    icon: <Icon.FaGlobe size={20} />,
    pageTitle: "Regions List",
    navLink: "/regions",
    type: "item",
    permissions: ["super_admin"],
  },
];
