const initialState = {
  toggleDrawer: false,
  callListingData: false,
  filteredFields: {},
  localStateFilteredFields: "",
  type: "",
};
export const RenderList = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_DRAWER": {
      return {
        ...state,
        toggleDrawer: action.payload.isToggle,
        type: action.payload.type || initialState.type,
      };
    }
    case "GET_LISTING_DATA": {
      return {
        ...state,
        callListingData: !state.callListingData,
      };
    }

    case "FILTER_LISTING_DATA": {
      return {
        ...state,
        filteredFields: action.payload.filteredData || {},
        localStateFilteredFields: JSON.stringify(action.payload.localState),
      };
    }

    case "RESET_LISTING_DATA": {
      return {
        ...state,
        filteredFields: {},
        localStateFilteredFields: "",
      };
    }
    default: {
      return state;
    }
  }
};
