import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import alert from "./alert";
import drawer from "./drawer";
import renderList from "./renderList";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  alert: alert,
  drawer: drawer,
  renderList: renderList,
});

export default rootReducer;
